/**
 * Filter Shows
 * @description : Filtered view of all shows
 */

const nvMedia = Vue.component('nv-media', {
    data: function () {
        return {
        }
    },
    props: ['media'],
    methods: {
        markAsLoaded: function (e) {
            window.markAsLoaded(e.srcElement);
        }
    },
    mounted: function () {

    }
});

(() => {

    const filterShowsEls = document.querySelectorAll('.js-filter-shows');

    filterShowsEls.forEach((filterShowsEl) => {
        const filterShowsVue = new Vue({
            el: filterShowsEl,

            data: {
                loading: false, // Initial loading state
                loadingMore: false, // Loading after 'load more' button clicked
                pagesLoaded: 0,
                maxPages: null,
                canLoadMore: true,
                showTypes: [],
                genres: [],
                venues: [],
                checkedDates: [],
                selectedType: null,
                selectedGenre: null,
                selectedVenue: null,
                firstLoad:true,
                requestFilters: {
                    // Do not change these property names
                    // Names required in api post request
                    PageNo: 1,
                    PageCount: filterShowsEl.dataset.maxShows,
                    FilterType: null,
                    FilterGenre: null,
                    FilterVenue: null,
                    FilterDateFrom: null,
                    FilterDateTo: null,
                    FilterCheckedDates: null
                },
                shows: [],
                gridView: true,
                cookieName: 'saved-events',
                cookieDayLength: 365,
                dateSlider: null,
                filterPanel: null,
                filterPanelDateSlider: null
            },
            methods: {
                seraliseToQueryString: function (obj) {
                    return Object.keys(obj).map(function (key) {
                        return key + '=' + obj[key];
                    }).join('&');
                },
                fetchShows: function () {
                    var cookie = this.getCookie(this.cookieName);
                    var savedEvents = cookie ? cookie.split(',') : [];
                    var self = this;
                    self.requestFilters.FilterType = self.selectedType;
                    self.requestFilters.FilterGenre = self.selectedGenre;
                    self.requestFilters.FilterVenue = self.selectedVenue;
                    self.requestFilters.FilterDateFrom = self.filterDateFrom;
                    self.requestFilters.FilterDateTo = self.filterDateTo;
                    self.requestFilters.FilterCheckedDates = self.checkedDates;

                    self.loading = true;
                    self.requestFilters.PageNo = 1;

                    if (self.firstLoad == true) {
                        if (self.selectedType != null) {
                            var path = `${window.location.pathname}?type=${self.selectedType}`;
                            console.log(path)
                            window.history.pushState({ path: path }, document.title, path);  
                        }
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        const type = urlParams.get('type')
                        self.requestFilters.FilterType = type;
                        self.selectedType = type;
                    } else {
                        
                        var path = `${window.location.pathname}?type=${self.selectedType}`;
                        window.history.pushState({ path: path }, document.title, path);
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        const type = urlParams.get('type')
                        self.requestFilters.FilterType = type;
                        self.selectedType = type;
					
					}
                    axios({
                        method: 'get',
                        url: '/api/shows/get?' + self.seraliseToQueryString(self.requestFilters)
                    })
                        .then(response => {
                            self.firstLoad = false;
                            self.showTypes = response.data.FilterTypes;
                            self.genres = response.data.FilterGenres;
                            self.venues = response.data.FilterVenues;
                            self.shows = response.data.Shows;
                            console.log('Shows:', self.shows);
                            self.loading = false;
                            self.pagesLoaded = self.pagesLoaded + 1;
                            self.canLoadMore = response.data.ShowLoadMore;
                            self.shows.forEach(function (show) {
                                show.Selected = savedEvents.filter(function (eventId) {
                                    return eventId === show.Id.toString();
                                }).length > 0;
                            });
                        })
                        .catch(error => {
                            self.errored = true;
                            self.loading = false;
                        });

                    console.log(self.firstLoad)
                },
                reset: function () {
                    this.checkedDates = [];
                    this.selectedType = null;
                    this.selectedGenre = null;
                    this.selectedVenue = null;

                    this.fetchShows();
                },
                setGridView: function () {
                    this.gridView = true;
                },
                setRowView: function () {
                    this.gridView = false;
                },
                loadMore: function () {
                    var self = this;
                    self.loading = true;
                    self.requestFilters.PageNo = self.pagesLoaded + 1;

                    axios({
                        method: 'get',
                        url: '/api/shows/get?' + self.seraliseToQueryString(self.requestFilters)
                    })
                        .then(response => {
                            self.shows = self.shows.concat(response.data.Shows);
                            self.loading = false;
                            self.pagesLoaded = self.pagesLoaded + 1;
                            self.canLoadMore = response.data.ShowLoadMore;
                        })
                        .catch(error => {
                            self.errored = true;
                            self.loading = false;
                        });
                },
                setCookie: function (name, value, daysToLive) {
                    var cookie = name + "=" + encodeURIComponent(value);
                    console.log(cookie);
                    if (typeof daysToLive === "number") {
                        cookie += "; max-age=" + (daysToLive * 24 * 60 * 60) + "; path=/;";
                        document.cookie = cookie;
                    }
                },
                getCookie: function (name) {
                    var cookieArr = document.cookie.split(";");
                    for (var i = 0; i < cookieArr.length; i++) {
                        var cookiePair = cookieArr[i].split("=");
                        if (name === cookiePair[0].trim()) {
                            return decodeURIComponent(cookiePair[1]);
                        }
                    }
                    return null;
                },
                manageSavedEvents: function (eventId, isSelected) {
                    var cookie = this.getCookie(this.cookieName);
                    var savedEvents = cookie ? cookie.split(',') : [];
                    // Remove any spurious empty values
                    savedEvents = savedEvents.filter(Boolean);

                    if (eventId) {
                        if (isSelected) {
                            // event is currently saved - remove from saved
                            savedEvents = savedEvents.filter(function (event) {
                                return event !== eventId;
                            });
                            this.setCookie(this.cookieName, savedEvents.join(','), this.cookieDayLength);

                            this.shows.filter(x => x.Id.toString() === eventId)[0].Selected = false;

                            console.log('event removed from saved. Cookie value: ' + this.getCookie(this.cookieName));

                        } else {
                            // event is not saves - let's save it!

                            if (savedEvents.indexOf(eventId) === -1) {
                                // New event ID to be added to the cookie

                                savedEvents.push(eventId);
                                this.setCookie(this.cookieName, savedEvents.join(','), this.cookieDayLength);
                            }

                            // GLEN: feel free to update these mutations                        
                            this.shows.filter(x => x.Id.toString() === eventId)[0].Selected = true;

                            console.log('event saved! Cookie value: ' + this.getCookie(this.cookieName));

                        }
                    }
                }
            },
            mounted: function () {
                var self = this;

                if (this.$refs.dateSlider) {
                    this.dateSlider = window.setUpDateSlider(this.$refs.dateSlider);
                }
                if (this.$refs.filterPanel) {
                    this.filterPanel = window.setUpFilterPanel(this.$refs.filterPanel);
                }
                if (this.$refs.filterPanelDateSlider) {
                    this.filterPanelDateSlider = window.setUpDateSlider(this.$refs.filterPanelDateSlider);
                }

                self.fetchShows();
            },
            computed: {
                filterCategory() {
                    var self = this;

                    return self.selectedCategory == null ? [] : self.selectedCategory;
                },
                filterLocation() {
                    var self = this;

                    return self.selectedLocation == null ? [] : self.selectedLocation;
                },
                filterDiscountType() {
                    var self = this;
                    return self.selectedDiscountType == null ? [] : self.selectedDiscountType;
                },
            }
        });
    });

})();
